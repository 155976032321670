@import '~bootstrap';
@import "toastr";
@import "font-awesome";
@import "~aos/src/sass/aos";
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;600;800&display=swap');

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


$primary: #23175b;
$secondary: #674fa0;
$third: #e32673;
$fourth: #fdcb0b;

.text-primary {
    color: $primary !important;
}
.text-third {
    color: $third !important;
}

a{
    text-decoration: none;
}

hr{
    border-top: 1px solid $third !important;
    opacity: 1;
}

img{
    max-width: 100%;
}

h1, h2, h3, h4, h5, h6{
    color: $primary;
}

h1{
    font-size: 54px;
}

.fs-14{
    font-size: 14px;
}
.fs-16{
    font-size: 16px;
}
.fs-18{
    font-size: 18px;
}
.fs-19{
    font-size: 19px;
}
.fs-20{
    font-size: 20px;
}
.fs-22{
    font-size: 22px;
}
.fs-24{
    font-size: 24px;
}
.fs-26{
    font-size: 26px;
}
.fs-30{
    font-size: 30px;
}
.fs-40{
    font-size: 40px;
}
.fs-50{
    font-size: 50px;
}
.fs-58{
    font-size: 58px;
}
.fs-60{
    font-size: 60px;
}
.fs-62{
    font-size: 62px;
}
.fs-70{
    font-size: 70px;
}

body{
    font-family: 'Sora', sans-serif;
    background-color: #ececf1;
    background-image: url(/assets/images/logo-bg-topo.png);
    background-repeat: no-repeat;
    background-position: top right;
}

.btn{
    --bs-btn-padding-x: 1.75rem;
    --bs-btn-padding-y: 0.50rem;
    --bs-btn-border-radius:1.1rem;
    border-radius: var(--bs-btn-border-radius);
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #23175b;
    --bs-btn-border-color: #23175b;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #674fa0;
    --bs-btn-hover-border-color: #674fa0;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #674fa0;
    --bs-btn-active-border-color: #674fa0;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #23175b;
    --bs-btn-disabled-border-color: #23175b;
}

.btn-light-primary {
    --bs-btn-color: #23175b;
    --bs-btn-bg: #fff;
    --bs-btn-border-color: #23175b;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #674fa0;
    --bs-btn-hover-border-color: #674fa0;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #674fa0;
    --bs-btn-active-border-color: #674fa0;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #23175b;
    --bs-btn-disabled-border-color: #23175b;
}

.btn-third {
    --bs-btn-color: #fff;
    --bs-btn-bg: #e32673;
    --bs-btn-border-color: #e32673;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #674fa0;
    --bs-btn-hover-border-color: #674fa0;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #674fa0;
    --bs-btn-active-border-color: #674fa0;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #e32673;
    --bs-btn-disabled-border-color: #e32673;
}

.btn-social{
    background-color: $third;
    padding: 0.35rem;
    font-size: 18px;
    color: #FFFFFF;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    text-align: center;
    vertical-align: middle;
}

.btn-social2{
    background-color: $third;
    padding: 0.45rem;
    font-size: 24px;
    color: #FFFFFF;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    vertical-align: middle;
}

.form-control{
    border: none;
    padding: 0.8rem 0.8rem;
    border-radius: 1.1rem;
}

#navbar{
    z-index: 9;
}

.navbar-toggler-close, .navbar-toggler-open {
    transition: opacity .2s ease,transform .2s ease;
    opacity: 1;
    transform: scale(1);
    position: absolute;
    top: 0;
    left: 0;
}

.nav-link{
    color: $primary;
    font-size: 19px;
    &:hover{
        color: $third;
    }
}

.dropdown-menu{
    --bs-dropdown-item-padding-x: 1.5rem;
}



.dropdown-toggle{
    &::before{
        content: "^";
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;        
        border-top: none;
        border-right: none;
        border-bottom: none;
        border-left: none;
        font-family: verdana;
        rotate: 180deg;
        font-size: 22px;
        position: absolute;
        color: $third;
        bottom: -10px;
        left: 8px;
        font-weight: bold;
        transform:scale(2,1); /* W3C */
        -webkit-transform:scale(2,1); /* Safari and Chrome */
        -moz-transform:scale(2,1); /* Firefox */
        -ms-transform:scale(2,1); /* IE 9 */
        -o-transform:scale(2,1); /* Opera */
    }
    &.fale-conosco-menu{
        &::before{
            content: none;
        }
    }
    &::after{
        content:none;
    }
    &.show{
        &::before{
            opacity: 0;
        }
        &::after{
            content:'';
            border: none;
            display: block;
            width: 100%;
            height: 100vh;
            background-color: $primary;
            opacity: 0.5;
            position: fixed;
            left: 0px;
            top: 85px;
            margin: 0;
            @media (max-width: 991px) {
                content:none;
            }
        }
    }
}

.navbar{
    &.scrolled{
        background-color: #ececf1;
        z-index: 9;
    }
    .btn{
        font-size: 16px;
        margin: 0px 0px 5px 15px;
        @media (max-width: 991px) {
            margin: 0px 0px 15px 0px;
        }
    }

    .navbar-nav{
        .dropdown-menu{
            border:none;
            max-height: 800px;
            overflow-y: auto;
            @media (max-height: 810px) {
                max-height: 500px;
                overflow-y: auto;
            }
        }
    }
}

.dropdown-menu{
    &[data-bs-popper]{
        margin-top: 20px;
        @media (max-width: 991px) {
            margin-top: 5px;
        }
    }
    .dropdown-item{
        color: $primary;
        &:hover{
            background-color: transparent;
            color: $third;
        }
    }
}



.navbar-toggler{
    padding: 0 0;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0;
    position: relative;
    width: 25px;
    height: 25px;
    &[aria-expanded=false] {
        .navbar-toggler-close {
            opacity: 0;
            transform: scale(0) rotate(45deg);
        }
    }
    &[aria-expanded=true]{
        .navbar-toggler-open {
            opacity: 0;
            transform: scale(0) rotate(-45deg);
        }
    }
    &:focus{
        box-shadow: none;
    }    
}

.no-row::before{
    content: none !important;
}

.bg-secondary{
    background-color: $secondary !important;
}
.bg-third{
    background-color: $third !important;
}

.pagination{
    justify-content: center;
    .page-link{
        color: $third;        
    }
    .active{
        .page-link{
            background-color: $third;
            color: #ececf1;
            border-color: #ececf1;
        }
    }
}
.nav-blog-categories{
    ul{
        list-style: none;
        text-align: center;
        padding: 0px;
        li{
            display: inline-block;
            color: $primary;
            a{
                color: $secondary;
                padding: 0 10px;
                &:hover,
                &.active{
                    color: $third;
                }
            }
        }
    }
}

.widget{
    margin-bottom: 30px;
    background-color: #FFF;
    padding: 20px;
    border-radius: 10px;
    .widget-title{
        color: $primary;
        border-bottom: 1px solid $third;
        padding: 5px;
    }
    p{
        font-size: 17px !important;
        color: $primary !important;
        line-height: 27px !important;
    }
    a{
        color: $primary;
        padding: 5px 0px;
        font-size: 14px;
        display: inline-block;
        &:hover{
            color: $third;
        }
    }
    ul, ol{
        li{
            &::marker{
                color: $third;
            }
        }
    }
}

.blog-article-author{
    .blog-article-author-img {
        float: left;
        width: 135px;
        padding: 30px 0;
        margin-bottom: 10px;
        border-radius: 30px 0 0 30px;
        text-align: center;
        img {
            border-radius: 50%;
        }
    }
    .blog-article-author-details {
        margin-left: 180px;
        padding-top: 30px;
    }
}

section{
    overflow: hidden;
    &.author{
        background-image: url(/assets/images/banners/quem-somos.webp);
        background-repeat: no-repeat;
        background-position: top;
        color: #FFFFFF;
        padding: 100px 0px 10px 0px;
        h5{
            color: #FFFFFF;
        }
    }
    &.blog-post{
        h1{
            font-size: 38px;
        }
        p{
            font-size: 17px !important;
            color: #212121;
            line-height: 27px !important;
        }
        figure{            
            &.blockquote{
                background-color: $primary;
                color: #FFFFFF;
                padding: 30px 80px;
                border-radius: 5px;
                position: relative;
                margin-top: 30px;
                blockquote{
                    color: #FFFFFF;
                }
                p{
                    color: #FFFFFF;
                }
                &::before{
                    font: normal normal normal 14px/1 FontAwesome;
                    content: '\f10d';
                    font-size: 40px;
                    position: absolute;
                    left: 15px;
                    top: 15px;
                    color: #dce7fd;
                }
                &::after{
                    font: normal normal normal 14px/1 FontAwesome;
                    content: '\f10e';
                    font-size: 40px;
                    position: absolute;
                    right: 15px;
                    bottom: 15px;
                    color: #dce7fd;
                }
            }   
        }
            
    }
    &.blog-list{
        article{
            background-color: #FFFFFF;
            margin-bottom: 20px;
            a{
                color: $primary;
            }
            .content{
                padding: 20px;
                h2{
                    font-size: 1.4rem;
                    min-height: 82px;
                }
                .post-details{
                    margin-bottom: 0.5rem;
                }
                .summary{
                    color: #888888;
                    font-size: 0.85rem;
                    min-height: 116px;
                }
            }
        }
    }
    &.banner{
        padding-top: 150px;
        padding-bottom: 30px;
        background-image: url(/assets/images/textura.png);
        .box-text{
            padding-top: 4rem !important;
        }
        p{
            font-size: 20px;
            line-height: 32px;
            color: #212121;
        }
        &.page{
            p{
                font-size: 16px;
                line-height: 28px;
            } 
        }
    }
    &.pbanner-home{
        background-image: url(/assets/images/textura.png);
        color: #FFF;        
        .bg{
            background-image: url(/assets/images/banners/home.png);
            padding: 150px 0px 150px 0px;
            background-repeat: no-repeat;
            min-height: 1044px;
        }
        h2{
            color: #FFFFFF;
            font-size: 56px;
        }
        h4{
            color: #FFFFFF;
            font-size: 27px;
        }
        p{
            font-size: 16px;
        }
    }
    &.pbanner-service{
        background-image: url(/assets/images/textura.png);
        color: #FFF;        
        .bg{            
            padding: 150px 0px 0px 0px;
            background-repeat: no-repeat;
            min-height: 1044px;
        }
        h2{
            color: #FFFFFF;
            font-size: 56px;
        }
        h4{
            color: #FFFFFF;
            font-size: 27px;
        }
        p{
            font-size: 20px;
            line-height: 35px;
        }
    }
    &.services{
        padding: 60px 0px 0px 0px;
        background-image: url(/assets/images/logo-repear-bg.png);
        position: relative;
        &::after{
            content: "";
            display: block;
            height: 130px;
            width: 100%;
            background-image: url(/assets/images/onda-topo.png);
            background-repeat: no-repeat;
            background-position: center;
            margin-top: 40px;
            -moz-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
        h2{
            font-size: 41px;
        }
        h3{
            strong{
                display: block;
            }
        }
        h6{
            font-size: 16px;
            color: #212121;
        }
        .slick-slide{
            padding: 15px;
            text-align: left;
            .ico-service{
                position: absolute;
                top: -60px;
                left: calc(50% - 41px);
                img{
                    display: inline-block;
                }
            }
            .item-content{
                background-color: #FFFFFF;
                border-radius: 1.1rem;
                padding: 20px;
                figure{
                    position: relative;
                    img{
                        max-width: 100%;
                        border-radius: 10px;
                    }
                }
                .text{
                    padding: 30px 0px;
                    position: relative;
                    .text-summary{
                        min-height: 64px;
                    }
                }
            }
        }
    }
    &.clients{
        background-color: #FFFFFF;
        padding: 20px 0px;
        overflow: hidden;
        h2{
            font-size: 56px;
        }
        h3{
            strong{
                display: block;
            }
        }
        h6{
            font-size: 16px;
            color: #212121;
        }
        .clients_brand{
            padding-top: 30px;
            text-align: center;
            img{
                max-height: 90px;
                margin: 10px 10px;
            }
        }        
    }
    &.beneficios-funcionarios{
        background-repeat: no-repeat;
        background-position: center;
        min-height: 900px;
        color: #FFFFFF;
        h2{
            color: #FFFFFF;
            font-size: 41px;
            @media (max-width: 991px) {
                text-shadow: 0.1em 0.1em 0.2em black;
            }
        }
        .row{
            min-height: 900px;
            align-items: center;
        }
    }
    &.missao{
        padding: 30px 0px 60px 0px;
        .box-white-text{
            width: 100%;
            min-height: 390px;
            border-radius: 10px;
            background-color: #ffffff;
            text-align: center;
            padding: 50px 20px;
            margin-bottom: 15px;
            h2{
                font-size: 27px;
                line-height: 29px;
                color: #211559;
                font-weight: 700;
            }
            p{
                font-size: 16px;
                line-height: 28px;
                color: #212121;
                font-weight: 300;
            }
        }        
    }
    &.sobre-fale-conosco{
        background-image: url(/assets/images/banners/quem-somos.webp);
        background-repeat: no-repeat;
        background-position: center;
        min-height: 711px;
        color: #FFFFFF;
        padding: 100px 0px;
        @media (max-width: 991px) {
            .cartao_beneficios{
                margin-top: 20px;
            }
        }
        h2{
            font-size: 41px;
            line-height: 49px;
            color: #ffffff;
            @media (max-width: 991px) {
                font-size: 36px;
            }
        }
    }
}


footer{
    padding: 60px 0px 0px 0px;
    background-image: url(/assets/images/logo-repear-bg.png);
    background-color: #fefeff;
    position: relative;
    h3{
        font-size: 30px;
        color: #22175a;
        font-weight: bold;
    }
    a{
        color: #202020;
        &:hover{
            color: $third;
        }
    }
    ul{
        list-style: none;
        padding: 0px;
        li{
            font-size: 16px;
            a{
                padding: 3px 0px;
                display: inline-block;
            }
        }
    }

    .assinatura{
        color: #90b4b7;
        a{
            color: #90b4b7;
            font-size: 13px;
        }
    }

    @keyframes slide_right {
        0% { left: 0px }
        50% { left: -270px }
        100% { left: -540px }
    }

    @keyframes slide_left {
        0% { left: -540px }
        50% { left: -270px }
        100% { left: 0px }
    }

    .box-newsletter{
        position: absolute;
        bottom: 15px;
        background-color: $third;
        padding: 30px;
        color: #FFFFFF;
        border-radius: 0px 35px 35px 0px;
        min-height: 600px;
        animation-duration: 3s;
        animation-name: slidein;
        animation-iteration-count: infinite;
        animation: slide_left 0.5s forwards;
        @media (max-width: 991px) {
            display: none;
        }
        &.closed{            
            animation: slide_right 0.5s forwards;
        }
        form{
            display: block;
            padding: 145px 30px;
            height: 550px;
            float: left;
        }
        .btn-newsletter{
            width: 80px;
            height: 550px;
            display: block;
            position: relative;
            float: right;
            cursor: pointer;
            h3{
                color: #FFFFFF;
                rotate: 90deg;
                position: absolute;
                top: 270px;
                width: 530px;
                right: -260px;
                font-weight: 300;
                font-size: 24px;
            }
        }

        input[type="text"],input[type="email"]
        {
            border-radius: 20px;
            border: none;
            background-color: #FFFFFF;
            padding: 15px 10px;
        }
        
    }
}

.dropdown-fale-conosco{
    min-width: 580px;
    margin-left: -300px;
    border-radius: 15px;
    padding: 1.5rem !important;
    .form-label{
        margin-bottom: 0.3rem;
        font-size: 14px;
    }
    select,input[type="text"],input[type="email"]
    {
        border-radius: 20px;
        border: none;
        background-color: #f0f0f0;
        padding: 15px 10px;
        @media (max-width: 991px) {
            padding: 12px 10px;
            font-size: 13px;
        }
    }
    @media (max-width: 991px) {
        min-width: 100%;
        margin-left: 0px;
        position: absolute !important;
        top: -155px !important;
        padding: 0.3rem 0rem !important;
    }
    @media (max-width: 575px) {
        h2{
            font-size: 18px;
        }
    }
}



.cartao_beneficios{
    &:hover{
        transform: translate3d(-4px,-4px,0) rotateZ(-2deg);
    }
}

.slick-prev{
    left: calc(50% - 50px);
}

.slick-next{
    right: calc(50% - 50px);
}

.slick-prev, .slick-next{
    width: 50px;
    height: 50px;
    top: initial;
    bottom: -70px;
    &::before{
        color: $primary;
        font-size: 35px;
        opacity:1;
    }
}

.ico-service{
    width: 82px;
    height: 83px;
    border-radius: 16px;
    background-color: #23175a;
    display: inline-block;
    padding: 15px 0;
    text-align: center;
    margin-bottom: 15px;
}


.bg-top-rodape{
    background-image: url(/assets/images/bg-top-rodape-quem-somos.webp);
    background-repeat: no-repeat;
    background-position: bottom center;
    padding-bottom: 115px !important;
}

.cookieconsent_box{
    width: inherit;
    max-width: inherit;
    position: relative;
    .cookieconsent{
        background-color: #FFFFFF;
        position: fixed;
        bottom: 20px;
        border-radius: 15px;
        padding: 15px;
        box-shadow: 10px 10px 15px #00000070;
        width: inherit;
        max-width: inherit;
        font-size: 14px;
        @media (max-width: 767px) {
            text-align: center;
            .btn{
                margin-top: 15px;
            }
        }
        h3{
            font-size: 18px;
        }
        
    }
}
